(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
/*jslint node: true, vars: true */

'use strict';

var $ = window.jQuery;

// *********************************************************
// svg test
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

var div = document.createElement('div');
div.innerHTML = '<svg/>';
if ((div.firstChild && div.firstChild.namespaceURI) !== 'http://www.w3.org/2000/svg') {
	$('html').addClass('no-svg');
}

},{}],2:[function(require,module,exports){
/*jslint node: true, vars: true, nomen: true */

(function (root) {
	
	'use strict';
	
	var $ = window.jQuery;
	
	require('svg-test');
	
	$(document).ready(function () {
		
		console.log('document ready : )');
		
		$('.phone-nav-button').on('click', function (eve) {
			
			$('.main-nav').toggleClass('show');
			
		});
		
	});
	
}(window));

},{"svg-test":1}]},{},[2]);
